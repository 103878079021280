<template>
      <v-navigation-drawer
        app
        v-model="drawer"
        :mini-variant.sync="mini"
        permanent
      >
        <v-list-item class="px-2">
          <v-list-item-avatar v-if="mini">
            <v-img src="../assets/img/logo.jpg"></v-img>
          </v-list-item-avatar>
  
          <v-list-item-title class="pa-2"><v-img src="../assets/img/logo.jpg"></v-img></v-list-item-title>
  
          <v-btn
            icon
            @click.stop="mini = !mini"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>
  
        <v-divider></v-divider>
  
        <v-list dense>
          <v-list-item 
            class="menubutton" 
            active-class="menubutton-active"
            v-for="item in items"
            :key="item.title"
            :to="item.path"
            link
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div
          v-if="stammdaten.length > 0">
            <v-list-group class="grpbutton"
                :value="stammopen"
                prepend-icon="mdi-database-outline"
            >
                <template v-slot:activator>
                <v-list-item-title>Stammdaten</v-list-item-title>
                </template>
                <v-list-item class="menubutton"
                    active-class="menubutton-active"
                    v-for="item in stammdaten"
                    :key="item.title"
                    :to="item.path"
                    link
                >
                <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
    
                <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            </v-list-group>
          </div>
        </v-list>
        <v-divider></v-divider>
    <v-footer app v-if="mini===false">
      <v-btn @click="logout" block color="error">Logout</v-btn>
    </v-footer>
    <v-footer app v-if="mini===true">
      <v-btn @click="logout" block color="error" style="width: 20px;">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-footer>

      </v-navigation-drawer>
  </template>
  
  <script>
  export default {
    name: 'SidebarMenu',
    data() {
      return {
        //route:null,
        drawer: true,
        //atoken: null,
        items: [
          { title: 'Home', icon: 'mdi-home', path:"/" },
          { title: 'Lagereingang (Offen)', icon: 'mdi-package', path: "/lagereingang"  },
        { title: 'Shipware (Lagerbestand)', icon: 'mdi-archive', path: "/shipware"  },
        { title: 'Packlisten', icon: 'mdi-inbox-multiple', path: "/packliste"  },
        { title: 'Item', icon: 'mdi-bag-personal-outline', path: "/item"},
        { title: 'Detailsuche', icon: 'mdi-file-document-edit-outline', path: "/detailsuche"}
        ],
        stammdaten: [
            {title: 'Absender', icon: 'mdi-account-arrow-right', path:"/absender"},
            {title: 'Kunde', icon: 'mdi-account-arrow-left', path:"/empfaenger"},
            {title: 'Schiffsliste', icon: 'mdi-ferry', path:"/schiffsliste"},
            {title: 'Verpackung', icon: 'mdi-package-variant', path:"/verpackung"},
            {title: 'Itemlisten', icon: 'mdi-format-list-bulleted-square', path:"/itemlisten"},
        ],
        mini: false,
        stammopen: false,
      }
    },
    methods: {
      logout() {
        window.localStorage.clear();
        this.$router.push('/login')
      }
    },
    created(){
      var role = window.localStorage.getItem('Role')
      if(role.includes('customer')){
        this.items = [
          { title: 'Advanced Search', icon: 'mdi-file-document-edit-outline', path: "/advancedsearch"},
          {title: 'Itemlist', icon: 'mdi-format-list-bulleted-square', path:"/customeritemlist"}
        ]
        this.stammdaten = []
      }
      else if(role.includes('user') || role.includes('buro')){
        this.stammdaten = []
      }
      else if(role.includes('lager')){
        this.stammdaten = [];
        this.items = [
          { title: 'Home', icon: 'mdi-home', path:"/" },
          { title: 'Lagereingang', icon: 'mdi-package', path: "/lagereingang"  },
          { title: 'Packlisten', icon: 'mdi-inbox-multiple', path: "/packliste"  },
      ]
      }else{
        this.stammdaten = [
            {title: 'Absender', icon: 'mdi-account-arrow-right', path:"/absender"},
            {title: 'Kunde', icon: 'mdi-account-arrow-left', path:"/empfaenger"},
            {title: 'Schiffsliste', icon: 'mdi-ferry', path:"/schiffsliste"},
            {title: 'Verpackung', icon: 'mdi-package-variant', path:"/verpackung"},
            {title: 'Itemlisten', icon: 'mdi-format-list-bulleted-square', path:"/itemlisten"},
        ]
        this.items.push({ title: 'Admin', icon: 'mdi-account-cog', path:"/admin" })
      }
    }
  }
  </script>
  
  <style>
  .mainred{
    background-color: #B4152C;
  }
  .menubutton, .grpbutton{
    background-color:#fff;
    color:#444;
  }
  .menubutton-active, .menubutton:hover, .grpbutton:hover{
    background-color:#B4152C!important;
    color:#fff !important;
  } 
  .menubutton:hover i.v-icon.v-icon{
    color:#fff !important;
  } 
  </style>