<template>
  <v-app>
    <SidebarMenu v-if="route.history.current.path!='/login'"/>
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <FooterBar/>
  </v-app>
</template>

<script>
//import HelloWorld from './components/HelloWorld';
//import SidebarMenuAkahon from './components/Sidebar-menu-akahon';
import SidebarMenu from './components/Sidebar-menu';
import FooterBar from './components/FooterBar';
import "animate.css"

export default {
  name: 'App',

  components: {
    //HelloWorld, SidebarMenuAkahon
    SidebarMenu, FooterBar
  },

  data() {
    return {
    };
  },
  computed:{
    route(){return this.$router}
  }
};
</script>
<style>

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: rgb(0, 0, 0) !important
}

.theme--light.v-data-table {
  color: rgb(0, 0, 0);
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > th {
  border-bottom: thin solid rgba(0,0,0,0.12) !important;
  border-left: thin solid rgba(0,0,0,0.12) !important;
}
</style>